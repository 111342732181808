'use client'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useCachedMyProfile } from './useMyProfile'
import dayjs from 'dayjs'
import { usePathname } from 'next/navigation'
import { atom, useAtom, useAtomValue } from 'jotai'
import { getLocalStorage, setLocalStorage } from '@/utils'
import { CACHE_KEY_FOLLOW_US_DIALOG, CACHE_KEY_NEW_FEATURE_DIALOG } from '@/constants'
import { auth0SignInAtom } from '@/atoms'
import useSurvey from './useSurvery'
import useTemplateOfTheDay from './useTemplateOfTheDay'
import { uniq } from 'lodash-es'
import { useCachedNewFeatureAnnouncement } from '@/hooks/useNewFeatureAnnouncement'

export interface UsePopupsResult {
  showTemplateOfTheDay: boolean
  showNewFeatureDialog: boolean
  showFollowUsDialog: boolean
}

type PopupId = 'templateOfTheDay' | 'newFeature' | 'followUs'

export const followUsDialogOpenAtom = atom<boolean>(getLocalStorage<boolean>(CACHE_KEY_FOLLOW_US_DIALOG) ?? true)
export const readedNewFeatureKeysAtom = atom<string[]>(getLocalStorage<string[]>(CACHE_KEY_NEW_FEATURE_DIALOG) ?? [])

export default function usePopups() {
  const { data: profile, isValidating: profileLoading } = useCachedMyProfile()
  const [followUsDialogOpen, setFollowUsDialogOpen] = useAtom(followUsDialogOpenAtom)
  const [readedNewFeatureKeys, setReadedNewFeatureKeys] = useAtom(readedNewFeatureKeysAtom)
  const { data: newFeatureAnnouncement, isValidating: newFeatureAnnouncementLoading } =
    useCachedNewFeatureAnnouncement()

  const lastPopupId = useRef<PopupId | null>(null)

  const { data: templateOfTheDay } = useTemplateOfTheDay()

  const isLogin = useAtomValue(auth0SignInAtom)
  const { showSurvey } = useSurvey()

  const [activePopupId, setActivePopupId] = useState<PopupId | null>(null)

  useEffect(() => {}, [])

  const pathname = usePathname()

  useEffect(() => {
    lastPopupId.current = activePopupId
  }, [activePopupId])

  const shouldNotPopup = useMemo(() => {
    return pathname.startsWith('/auth') || pathname.startsWith('/onboarding') || pathname === '/event'
  }, [pathname])

  const isNewUser = useCallback(() => {
    const date = dayjs(profile?.create_time || '').unix()
    const now = dayjs().unix()
    return now - date < 24 * 60 * 60
  }, [profile])

  const loading = profileLoading || newFeatureAnnouncementLoading

  useEffect(() => {
    if (loading || lastPopupId.current) {
      return
    }

    if (followUsDialogOpen) {
      setActivePopupId('followUs')
      // } else if (templateOfTheDay) {
      //   setActivePopupId('templateOfTheDay')
      // }
    } else if (newFeatureAnnouncement && !readedNewFeatureKeys.includes(newFeatureAnnouncement.key)) {
      setActivePopupId('newFeature')
    } else {
      setActivePopupId(null)
    }
  }, [loading, followUsDialogOpen, templateOfTheDay, newFeatureAnnouncement, readedNewFeatureKeys])

  const disableAllPopups = loading || shouldNotPopup || !isLogin || showSurvey

  const showTemplateOfTheDay = useMemo(() => {
    if (disableAllPopups) {
      return false
    }

    return activePopupId === 'templateOfTheDay'
  }, [disableAllPopups, activePopupId])

  const showNewFeatureDialog = useMemo(() => {
    if (disableAllPopups) {
      return false
    }
    return !!(
      activePopupId === 'newFeature' &&
      newFeatureAnnouncement?.key &&
      !readedNewFeatureKeys.includes(newFeatureAnnouncement?.key)
    )
  }, [activePopupId, disableAllPopups, readedNewFeatureKeys, newFeatureAnnouncement])

  const showFollowUsDialog = useMemo(() => {
    if (disableAllPopups) {
      return false
    }
    return activePopupId === 'followUs' && followUsDialogOpen
  }, [activePopupId, disableAllPopups, followUsDialogOpen])

  const closeDialogById = useCallback(
    (id: string) => {
      const newKeys = uniq([...readedNewFeatureKeys, id])
      setReadedNewFeatureKeys(newKeys)
      setLocalStorage(CACHE_KEY_NEW_FEATURE_DIALOG, newKeys)
      setActivePopupId(null)
    },
    [readedNewFeatureKeys, setReadedNewFeatureKeys],
  )

  const closeNewFeatureDialog = useCallback(() => {
    const id = newFeatureAnnouncement?.key ?? ''
    closeDialogById(id)
  }, [closeDialogById, newFeatureAnnouncement])

  const closeTemplateOfTheDayDialog = useCallback(() => {
    closeDialogById(templateOfTheDay?.popup_id ?? '')
  }, [closeDialogById, templateOfTheDay])

  const closeFollowUsDialog = useCallback(() => {
    setFollowUsDialogOpen(false)
    setActivePopupId(null)
  }, [setFollowUsDialogOpen])

  const openTemplateOfTheDay = useCallback(() => {
    setActivePopupId('templateOfTheDay')
    // remove the template of the day popup from the new feature dialog
    setReadedNewFeatureKeys((keys) => keys.filter((key) => key !== templateOfTheDay?.popup_id))
  }, [setReadedNewFeatureKeys, templateOfTheDay])

  const openNewFeatureDialog = useCallback(() => {}, [])
  const openFollowUsDialog = useCallback(() => {
    setActivePopupId('followUs')
  }, [])

  return {
    showTemplateOfTheDay,
    showNewFeatureDialog,
    showFollowUsDialog,
    closeTemplateOfTheDayDialog,
    closeNewFeatureDialog,
    closeFollowUsDialog,
    openTemplateOfTheDay,
    openNewFeatureDialog,
    openFollowUsDialog,
  }
}
