import { useMemo } from 'react'
import useMarketingEventsInKV from './useMarketingEventsInKV'

const useTemplateOfTheDay = () => {
  const { data: marketingEventsRes, ...others } = useMarketingEventsInKV()
  const marketingEvents = useMemo(() => marketingEventsRes ?? [], [marketingEventsRes])

  const templateOfToday = marketingEvents?.find((event) => event.event_id === 'template_of_the_day')
  const config: haiper.TemplateOfTheDayConfig | null = (templateOfToday?.action?.payload as any) ?? null

  return {
    ...others,
    data: config,
    event: templateOfToday ?? null,
  }
}

export default useTemplateOfTheDay
